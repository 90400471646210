<template>
  <div style="background-color: #fff; width: 100%">
    <v-row class="pa-0 ma-0">
      <!-- <v-col cols="12"> -->
        <v-img :src="parents.photo" max-height="300px" dark contain>
          <v-card
            class="d-flex align-end mb-1 rounded-0"
            color="transparent"
            elevation="0"
            height="300px"
            width="100%"
          >
            <v-card
              width="100%"
              style="background-color: #0d0d0d2e !important"
              elevation="0"
              class="pa-2 text-center rounded-0 d-sm-none"
              color="transparent"
            >
              <h2>{{ parents.firstname + " " + parents.lastname }}</h2>
              <p>{{ parents.email }}</p>
            </v-card>
          </v-card>
        </v-img>
      <!-- </v-col> -->
      <v-col cols="12">
        <v-list>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="indigo"> mdi-account </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title
                v-text="parents.firstname + ' ' + parents.lastname"
              ></v-list-item-title>
              <v-list-item-subtitle>Nama Lengkap</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider inset></v-divider>

          <v-list-item two-line>
            <v-list-item-icon>
              <v-icon color="indigo"> mdi-phone </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="parents.phone_no"></v-list-item-title>
              <v-list-item-subtitle>No. Telp</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider inset></v-divider>

          <v-list-item two-line>
            <v-list-item-icon>
              <v-icon color="indigo"> mdi-email </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="parents.email"></v-list-item-title>
              <v-list-item-subtitle>Email</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider inset></v-divider>

          <v-list-item two-line>
            <v-list-item-icon>
              <v-icon color="indigo"> mdi-map-marker </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="parents.address"></v-list-item-title>
              <v-list-item-subtitle>Alamat</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <div class="ml-4 mr-8">
          <v-btn
            class="ma-2"
            outlined
            color="success"
            @click="changePhotoDialog = !changePhotoDialog"
            block
          >
            Ubah Photo
          </v-btn>
          <v-btn
            class="ma-2"
            outlined
            color="warning"
            @click="changePasswordDialog = !changePasswordDialog"
            block
          >
            Ubah Password
          </v-btn>
          <v-btn class="ma-2" outlined color="red" @click="logout" block>
            Keluar
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="changePasswordDialog" max-width="500px" :fullscreen="this.$vuetify.breakpoint.name == 'xs'">
      <v-card>
        <v-card-title> Ubah Password 

          <v-spacer></v-spacer>
          <v-btn icon @click="changePasswordDialog = !changePasswordDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text class="pt-4">
          <v-form ref="fmResetPassword" v-model="valid" lazy-validation>
            <v-text-field
              label="Kode Verifikasi"
              type="text"
              v-model="verificationCode"
              :rules="[verificationCodeRules.required]"
              outlined
            ></v-text-field>
            <p class="mb-4 pb-0" v-if="reqCounter == 0 && reqCounterDown == 0">
              <a href="#" @click="requestVerificationCode"
                >Minta Kode Verifikasi</a
              >
            </p>
            <p class="mb-4 pb-0" v-if="reqCounter >= 1 && reqCounterDown >= 1">
              Minta Ulang dalam ({{ reqCounterDown }})
            </p>
            <p class="mb-4 pb-0" v-if="reqCounter >= 1 && reqCounterDown == 0">
              <a href="#" @click="requestVerificationCode"
                >Minta Ulang Kode Verifikasi</a
              >
            </p>
            <v-text-field
              label="Password Baru"
              v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              hint="Minimal 6 Karakter"
              counter
              @click:append="show1 = !show1"
              outlined
            ></v-text-field>
            <v-text-field
              label="Ketik Ulang Password Baru"
              v-model="password2"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="show2 ? 'text' : 'password'"
              name="input-10-1"
              hint="Minimal 6 Karakter"
              counter
              @click:append="show2 = !show2"
              outlined
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" @click="submit" :block="this.$vuetify.breakpoint.name == 'xs'">Ubah Password</v-btn>
          <v-btn @click="changePasswordDialog = !changePasswordDialog"
            v-if="this.$vuetify.breakpoint.name != 'xs'"
            >Batal</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="changePhotoDialog" max-width="500px">
      <v-card>
        <v-card-title>Ubah Photo</v-card-title>
        <v-card-text>
          <v-img :src="fileImg" class="mb-2"></v-img>
          <input type="file" @change="onFileChange" ref="refFileUpload" style="display:none">
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="$refs.refFileUpload.click()">Pilih Photo</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="submitPhoto">Simpan</v-btn>
          <v-btn @click="changePhotoDialog = !changePhotoDialog">Batal</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <input
      type="file"
      name="fileupload"
      id="fileupload"
      style="display: none"
      ref="fileupload"
    />
  </div>
</template>

<script>
export default {
  name: "Parents",
  components: {},

  data: () => ({
    parents: {},
    parents_parents: {},
    changePasswordDialog: null,
    changePhotoDialog: null,
    show1: false,
    show2: false,
    password: "",
    password2: "",
    rules: {
      required: (value) => !!value || "Tidak boleh kosong.",
      min: (v) => v.length >= 6 || "Min 6 characters",
    },
    verificationCode: "",
    verificationCodeRules: {
      required: (value) => !!value || "Tidak boleh kosong.",
    },
    valid: null,
    reqCounter: 0,
    reqCounterDown: 0,
    fileUpload: null,
    fileImg: null,
  }),

  computed:{
  },

  watch: {
    reqCounterDown: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.reqCounterDown--;
          }, 1000);
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    loadInfo() {
      try {
        this.$axios
          .get("user/parents")
          .then((res) => {
            if (res.data.status == "success") {
              this.parents = res.data.data.parents;
            } else {
              this.parents = {};
            }
          })
          .catch((error) => {
            console.log(error);
            this.showAlert("error", "Internal Server Error!");
            this.$store.state.showLoadingDialog = false;
          })
          .finally(() => (this.$store.state.showLoadingDialog = false));
      } catch (error) {
        this.showAlert("error", error);
      }
    },

    logout() {
      let notifier = this.$awn;
      let onOk = () => {
        this.$store.state.apiToken = "";
        this.$store.state.loginInfo.username = "";
        this.$store.state.loginInfo.firstName = "";
        this.$store.state.loginInfo.lastName = "";
        this.$store.state.loginInfo.profileImage = "";
        this.$store.state.loginInfo.email = "";
        this.$store.state.loginInfo.userType = "";

        this.$store.state.isLoggedIn = false;
        // this.$store.state.showLoadingDialog = false;
        this.$router.push("/login");
      };
      let onCancel = () => {
        return null;
      };
      notifier.confirm("Logout sekarang?", onOk, onCancel, {
        labels: {
          confirm: "Konfirmasi",
        },
      });
    },

    requestVerificationCode() {
      try {
        if (this.reqCounterDown > 0) {
          this.showAlert("warning", "Tunggu beberapa saat.");
          return;
        }

        this.$store.state.showLoadingDialog = true;
        this.$axios
          .get("account/change-password/request-verification-code")
          .then((res) => {
            this.$store.state.showLoadingDialog = false;

            if (res.data.status == "success") {
              this.reqCounter = this.reqCounter + 1;
              this.reqCounterDown = this.reqCounter * 15;
              this.showAlert(res.data.status, res.data.message);
            } else {
              this.showAlert(res.data.status, res.data.message);
            }
          })
          .catch((error) => {
            console.log(error);
            this.$store.state.showLoadingDialog = false;
            this.showAlert("error", "Internal Server Error!");
          })
          .finally(() => (this.$store.state.showLoadingDialog = false));
      } catch (error) {
        this.$store.state.showLoadingDialog = false;
        this.showAlert("error", error);
      }
    },

    submit() {
      if (this.$refs.fmResetPassword.validate()) {
        try {
          if (this.password !== this.password2) {
            this.showAlert("error", "Ketik ulang password baru tidak sama.");
            this.password2 = "";
            return;
          }

          this.$store.state.showLoadingDialog = true;
          let formData = new FormData();
          formData.append("conf_code", this.verificationCode);
          formData.append("new_password", this.password);
          this.$axios
            .post("account/change-password/save", formData)
            .then((res) => {
              this.$store.state.showLoadingDialog = false;

              if (res.data.status == "success") {
                this.showAlert(res.data.status, res.data.message);

                this.$store.state.apiToken = "";
                this.$store.state.loginInfo.username = "";
                this.$store.state.loginInfo.firstName = "";
                this.$store.state.loginInfo.lastName = "";
                this.$store.state.loginInfo.profileImage = "";
                this.$store.state.loginInfo.email = "";
                this.$store.state.loginInfo.userType = "";

                this.$store.state.isLoggedIn = false;
                // this.$store.state.showLoadingDialog = false;
                this.$router.push("/login");
              } else {
                this.showAlert(res.data.status, res.data.message);
              }
            })
            .catch((error) => {
              console.log(error);
              this.$store.state.showLoadingDialog = false;
              this.showAlert("error", "Internal Server Error!");
            })
            .finally(() => (this.$store.state.showLoadingDialog = false));
        } catch (error) {
          this.$store.state.showLoadingDialog = false;
          this.showAlert("error", error);
        }
      }
    },

    reset() {
      this.$refs.fmResetPassword.reset();
    },

    resetValidation() {
      this.$refs.fmResetPassword.resetValidation();
    },

    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.fileUpload = files[0]
      this.createImage(files[0]);
    },

    createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.fileImg = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    submitPhoto() {
      try {
        let formData = new FormData();
        formData.append("photo", this.fileUpload);

        this.$store.state.showLoadingDialog = true;

        this.$axios
          .post("account/change-photo", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            this.$store.state.showLoadingDialog = false;

            if (res.data.status == "success") {
              this.showAlert(res.data.status, res.data.message);

              this.$store.state.profileImage = res.data.data.photo_url;

              this.loadInfo();

              this.changePhotoDialog = null
              this.fileUpload = null
              this.fileImg = null
            } else {
              this.showAlert(res.data.status, res.data.message);
            }
          })
          .catch((error) => {
            console.log(error);
            this.$store.state.showLoadingDialog = false;
            this.showAlert("error", "Internal Server Error!");
          })
          .finally(() => (this.$store.state.showLoadingDialog = false));
      } catch (error) {
        this.$store.state.showLoadingDialog = false;
        this.showAlert("error", error);
      }
    },
  },

  mounted() {
    this.loadInfo();
  },
};
</script>